import React, { useEffect, useState } from 'react';
// import Navbar from "../../Components/Navbar";
// import Contactform from "../../Components/Contactform";
// import Footer from "../../Components/Footer";
import { FloatButton, Modal, Button, Checkbox, Form, Input, message } from 'antd';
import { RxVideo } from 'react-icons/rx';
import { BiVideo, BiSolidBot, BiPlay } from 'react-icons/bi';
import ReactPlayer from 'react-player';
import Navbar from '../Components/Navbar';
import Contactform from '../Components/Contactform';
import Footer from '../Components/Footer';
import CardCount from '../Components/CardCount';
import manImage from '../assets/man and woman working with laptop.png';
import { MdLocationOn } from 'react-icons/md';
import { BsFillBuildingFill } from 'react-icons/bs';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FocusRMap from '../assets/Map-Of-FocusR.svg';
import IndianFlag from '../assets/India (IN).png';
import AmericanFlag from '../assets/United States of America (US).png';
import DubaiFlag from '../assets/United Arab Emirates (AE).png';
import MalaysiaFlag from '../assets/Malaysia (MY).png';
import SingaporeFlag from '../assets/Singapore (SG).png';
import { FiArrowRight } from 'react-icons/fi';
// import videoOne from '../assets/video-7-T.mp4';
import BreadCrum from '../Components/BreadCrum';
import ChatNew from '../Components/Chat2';
import axios from 'axios';
const ContactUs = () => {
  const [newform] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [jobTitlestate, setJobtitlestate] = useState('');
  // const showModal = () => {
  //   setOpen(true);
  // };
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: `You've Sucessfully Applied for ${jobTitlestate} role \n Our HR Team will contact you shortly`,
      duration: 5
    });
  };
  const error = (rr) => {
    messageApi.open({
      type: 'error',
      content: `${rr}`,
      duration: 5
    });
  };
  const perjob = (x) => {
    console.log('Per-Job : ', x);
    setOpen(true);
    setJobtitlestate(x);
  };
  const handleOk = () => {
    setLoading(true);
    onFinish();
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onFinish = (values) => {
    /*
    {
    "first_Name": "John",
    "last_Name": "Doe",
    "email": "john.doe@example.com",
    "mobile": "1234567890",
    "notice_Period": "30 days",
    "years_Of_Experience": "5",
    "skills": "Python, Django, JavaScript",
    "applied_Role": "Software Developer"
}
    */
    console.log('Success:', values);
    axios
      .post('https://172.235.6.164:85/post/apply', {
        first_Name: newform.getFieldValue('firstname'),
        last_Name: newform.getFieldValue('lastname'),
        email: newform.getFieldValue('email'),
        mobile: newform.getFieldValue('mobile'),
        notice_Period: newform.getFieldValue('noticeperiod'),
        years_Of_Experience: newform.getFieldValue('experience'),
        skills: newform.getFieldValue('skills'),
        applied_Role: jobTitlestate
      })
      .then((res) => {
        console.log('Resposne after send Contact Form', res);
        success();
        newform.resetFields();
        setOpen(false);
      })
      .catch((err) => {
      //  message.error(`Error while submitting form${err}`);
        error(`Error while submitting form${err}`)
      });
    newform.resetFields();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    error();
  };

  useEffect(() => {
    document.title = 'Contact Us';
    AOS.init();
  });

  
  return (
    <div>
      {/* navbar here */}
      <Navbar mode="dark" />
      <ChatNew />
      {/* First Page starts */}
      <div className="first-page-wrapper-career">
        {/* <video autoPlay loop muted className="videoBg-1">
          <source src={videoOne} type='video/mp4'/>
        </video> */}
        <div className="inner-page-left-product-dealerportal">
          {/* <span  style={{
          fontWeight:'600'
        }}>
            FocusR <FiArrowRight /> About <FiArrowRight /> Company
          </span> */}
          {/* <BreadCrum color="#fff" items={['FocusR', 'About', 'Company']} /> */}
          <br />
          <h1
            style={{
              color: '#fff'
            }}
          >
            Connect with FocusR and Success Together
          </h1>
          <br />
          <p
            style={{
              color: '#fff'
            }}
          >
            Have you discovered the solution you seek, or are you still in need of assistance? <br /> We're here to lend
            a hand.
          </p>
          <br />
          <br />
          <a className="primary-btn-newcus" href="#Contact-Form">
            Get Details
          </a>
        </div>
      </div>

      {/* explanation page starts here */}
      {/* focusR Map Here */}
      <div className="map-holder">
        <h1 >
          Come to Visit us
        </h1>
        <p className='new-map-p'>
          With several offices in India and beyond, you’re more than welcome to come to visit us at any convenient time
          and meet your team in person.
        </p>
        <img className='fr-loc' src={FocusRMap} alt="FocusR-Locations" width='100%'/>
        <div className="flag-card-Holder">
          <div className="flag-item-wrapper" >
            <img src={IndianFlag} alt="Indian-Flag" />
            <h1>
              India,Chennai <br /> & Salem <br />
              <span className="flag-address">Corporate Office</span>
            </h1>
            {/* <h1>
              Phone <br />
              <span className="flag-address"> 044 4550 0999</span>
            </h1> */}
          </div>
          <div className="flag-item-wrapper" >
            <img src={AmericanFlag} alt="American-Flag" />
            <h1>
              Irving,Texas <br />
              <span className="flag-address">
                FocusR Digital <br /> Transformation LLC
              </span>
            </h1>
            {/* <h1>
              Phone <br />
              <span className="flag-address"></span>
            </h1> */}
          </div>
          <div className="flag-item-wrapper" >
            <img src={DubaiFlag} alt="Dubai-Flag" />
            <h1>
              UAE,Dubai
              <br />
              <span className="flag-address">
                Focus Right Consultancy <br /> and Technology LLC
              </span>
            </h1>
            {/* <h1>
              Phone <br />
              <span className="flag-address"></span>
            </h1> */}
          </div>
          <div className="flag-item-wrapper" >
            <img src={MalaysiaFlag} alt="Malaysia-Flag" />
            <h1>
              Malaysia,Johor Bahru
              <br />
              <span className="flag-address">
                FocusR Digital <br /> & AI Technologies
              </span>
            </h1>
            {/* <h1>
              Phone <br />
              <span className="flag-address">+91 987654321</span>
            </h1> */}
          </div>
          <div className="flag-item-wrapper" >
            <img src={SingaporeFlag} alt="Singapore-Flag" />
            <h1>
              Singapore,Sim Lim Tower <br />
              <span className="flag-address">
                FocusR Consultancy <br /> and Technologies Pvt Ltd
              </span>
            </h1>
            {/* <h1>
              Phone <br />
              <span className="flag-address"></span>
            </h1> */}
          </div>
        </div>
      </div>

      {/* focusR Map Ends Here */}
      <Modal open={open} title={jobTitlestate} onOk={handleOk} onCancel={handleCancel} footer={false}>
        <Form
          name="basic"
          labelCol={{
            span: 8
          }}
          wrapperCol={{
            span: 16
          }}
          style={{
            minWidth: '100%'
          }}
          initialValues={{
            remember: true
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={newform}
        >
          <Form.Item
            label="FirstName"
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Please input your FirstName!'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="LastName"
            name="lastname"
            rules={[
              {
                required: true,
                message: 'Please input your LastName!'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your Email!'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[
              {
                required: true,
                message: 'Please input your Mobile No!'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Notice Period"
            name="noticeperiod"
            rules={[
              {
                required: true,
                message: 'Please input your Notice Period!'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Years of Experience"
            name="experience"
            rules={[
              {
                required: true,
                message: 'Please input your Experience!'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Skills"
            name="skills"
            rules={[
              {
                required: true,
                message: 'Please input your Skills!'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <br />
      <br />

      <div id="Contact-Form">
        <Contactform />
      </div>
      <Footer />
      {contextHolder}
    </div>
  );
};

export default ContactUs;
