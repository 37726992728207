import React, { useEffect, useState } from 'react';
import Navbar from '../../Components/Navbar';
import { useNavigate } from "react-router-dom";
import Footer from '../../Components/Footer';
import { message } from "antd";
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from 'axios';
import './ERP.css'
import integrahub from "../../assets/ERP-Icons/integrahub.png";
import arrow from "../../assets/ERP-Icons/arrow.png";
import oracle from "../../assets/ERP-Icons/oracle.png";
import sap from "../../assets/ERP-Icons/sap.png";
import man from "../../assets/ERP-Icons/man.png";
import countmans from "../../assets/ERP-Icons/countmans.png";
import tick from "../../assets/ERP-Icons/tick.png";
import s1 from "../../assets/ERP-Icons/s1.png";
import s2 from "../../assets/ERP-Icons/s2.png";
import { FaFacebook } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { ImQuotesLeft } from "react-icons/im";

const ERP = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      left: [
        {
          img: s1,
          alt: "s1",
          text: "Premier Oracle Partner Network Specialist",
          desc: "Trust our seasoned experts for seamless Oracle implementation and robust support.",
        },
        {
          img: s2,
          alt: "s2",
          text: "Efficiency and Reliability",
          desc: "We ensure every service is efficient and reliable.",
        },
        {
          img: tick,
          alt: "tick",
          text: "Unparalleled Excellence",
          desc: "Join us in achieving success where Oracle implementation meets unparalleled excellence!",
        },
      ],
      right: "ORACLE",
    },
    {
      left: [
        {
          img: sap,
          alt: "s1",
          text: "SAP Implementation and Support",
          desc: "We specialize in implementing and supporting SAP solutions, ensuring efficient and reliable system integration.",
        },
        {
          img: tick,
          alt: "tick",
          text: "Proven Track Record",
          desc: "FocusR is a leader in SAP consulting with a proven track record, offering tailored solutions to meet unique client needs.",
        },
        {
          img: s2,
          alt: "s2",
          text: "Maximizing SAP Investments",
          desc: "From implementation to ongoing support, our expert team maximizes the value of SAP investments.",
        },
      ],
      right: "SAP",
    },
  ];

  // ERP count
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  useEffect(() => {
    AOS.init();
    document.title = 'Services | ERP';
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [about, setAbout] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (!name || !email || !about) {
      message.error("All fields are required!");
      return;
    }

    setLoading(true);
    const payload = {
      full_Name: name,
      email: email,
      about: about,
      type: "Service-ERP",
    };
    axios
      .post("https://focusrapi.focusrtech.com:83/post/enquery", payload)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        message.success("Submitted Successfully!");
        setName("");
        setEmail("");
        setAbout("");
      })
      .catch((error) => {
        console.error("There was an error making the request!", error);
        message.error("Failed to Submit");
        setLoading(false);
      });
  };
  return (
    <div>
      <Navbar mode="dark" />
      <div style={{width: "100vw"}}>
      <div className="custome-erp-ERP-Enterprise">
        <div className='custome-erp-ERP-wrapper'>
        <div className="custome-erp-ERP_left">
          <h3 className="custome-erp-erp-title">ERP Resource Planning</h3>
          <h1 className="custome-erp-erp-main-title">
            Unified ERP
            <br /> Services
          </h1>
          <h1 className="custome-erp-erp-sub-title">At FOCUSR</h1>
          <hr
            style={{
              width: "4em",
              border: "none",
              borderTop: "0.2em solid #FFBD1E",
              marginTop: "2em",
            }}
          />
          <div className="custome-erp-reqcall" onClick={() => navigate("/contact")}>
            <h4 style={{color: "white", marginLeft: "10px"}}>Request a Call</h4>
          </div>
          <div className="custome-erp-erp-first-down">
            <img src={tick} alt="oracle-tick" style={{ width: "2.5em" }} />
            <p>Oracle Products</p>
          </div>
          <div className="custome-erp-erp-second-down">
            <img src={tick} alt="oracle-tick" style={{ width: "2.5em" }} />
            <p>SAP Products</p>
          </div>
        </div>
        <div className="custome-erp-erp-right">
          <h2 style={{ marginTop: "-1em", fontSize: "1.2rem", marginBottom: "1em", color: "#000", textAlign: "center" }}>
            Try FocusR ERP Services
          </h2>
          <form className="custome-erp-erp-form">
            <input
              type="text"
              placeholder="Your Name"
              className="custome-erp-erp-form input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Your Email"
              className="custome-erp-erp-form input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              placeholder="Please tell us how we can assist you with ERP..."
              style={{ marginBottom: "1em" }}
              className="custome-erp-erp-form textarea"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
            ></textarea>
            <button onClick={handleSubmit} loading={loading} type="button">
              {loading ? "Loading" : "HAVE A CALL WITH EXPERTS"}
            </button>
          </form>
          <div className="custome-erp-contact-options">
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
              <p style={{ color: "grey", marginRight: "1em", marginBottom: "10px" }}>
                or contact us in
              </p>
              <a
                href="https://www.facebook.com/focusrtechnologies/"
                style={{
                  marginRight: "1em",
                  marginTop: "-7px",
                  fontSize: "23px",
                  color: "grey"
                }}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <FaFacebook />
              </a>
              <a
                href="https://www.linkedin.com/company/focusr-consultancy-and-technologies-pvt-ltd/"
                style={{ marginTop: "-6.7px", fontSize: "29px", color: "grey" }}
                target="_blank"
                rel="noreferrer"
              >
                <TiSocialLinkedinCircular />
              </a>
            </div>
          </div>
        </div>
        </div>
      </div>
      </div>
      <div className="custome-erp-slider1">
        {slides.map((slide, index) => (
          <div
            className="custome-erp-slider1-one"
            style={{ display: currentSlide === index ? "flex" : "none" }}
            key={index}
          >
            <div className="custome-erp-slider1-heading-container">
              <p className="custome-erp-slider1-heading">
                {index === 0 ? (
                  <>
                    Our Core <span style={{ color: "#FFC532" }}>Oracle</span>{" "}
                    Services
                  </>
                ) : (
                  <>
                    Our Core <span style={{ color: "#FFC532" }}>SAP</span>{" "}
                    Services
                  </>
                )}
              </p>
            </div>

            <div className="custome-erp-slide1-left">
              {slide.left.map((item, idx) => (
                <div className="custome-erp-slide1-one" key={idx}>
                  <img
                    src={item.img}
                    alt={item.alt}
                    style={{ width: "20%", height: "auto" }}
                  />{" "}
                  &nbsp;
                  <p>
                    "{item.text}"<br />
                    <br />
                    {item.desc}
                  </p>
                </div>
              ))}
            </div>
            <div className="custome-erp-slide1-right">
              <div>
                <ImQuotesLeft
                  style={{ color: "#FFC532", fontSize: "1.5rem" }}
                />
                <h1 style={{ color: "white", opacity: "100%" }}>WHY FOCUSR</h1>
                <h1 style={{ color: "white" }}>FOR</h1>
                <h1 style={{ color: "#FFC532" }}>{slide.right} </h1>
                <h1 style={{ color: "white" }}>SERVICE</h1>
              </div>
              {/* s<hr style={{ width: "3.5em", border: "none", borderTop: "0.2em solid #FFBD1E", marginTop: "3em",marginLeft:"9em",marginRight:"-6em" }} /> */}
            </div>
          </div>
        ))}
        <div className="custome-erp-slider1-dots">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`custome-erp-dot1 ${currentSlide === index ? "active" : ""}`}
              onClick={() => goToSlide(index)}
            ></span>
          ))}
        </div>
      </div>

      {/* third Container */}
      <div className="custome-erp-erp-third">
        <div className="custome-erp-erp-integrahub">
          <img src={integrahub} style={{ width: "100%" }} />
        </div>
        <div className="custome-erp-erp-third-right">
          <h5>Spotlight</h5>
          <h3>
            Integrate and migrate
            <br /> data with ease using
          </h3>
          <hr style={{ fontWeight: "bold", color: "yellow" }} />
          <h1>IntegraHub</h1>
          <img
            src={arrow}
            alt="arrow-nav"
            style={{ width: "6em" }}
          />
        </div>
      </div>

      {/* Fourth Container */}
      <div className="custome-erp-erp-fourth">
        <div className="custome-erp-erp-oracle">
          <div className="custome-erp-erp-oracle-top">
            <img src={oracle} alt="oracle-img" style={{ width: "100%" }} />
          </div>
          <div className="custome-erp-erp-oracle-bottom">
            <p style={{ marginBottom: "1em", fontSize: "1.2rem" }}>
              We offer an end-to-end <br />
              implementation <br />
              and support for Oracle ERP.
            </p>
            <a
              href="#"
              style={{ color: "blue", padding: "1em" }}
              onClick={() => {
                navigate("/platforms/Oracle");
              }}
            >
              Learn More
            </a>
          </div>
        </div>
        {/* second */}
        <div className="custome-erp-erp-sap">
          <div className="custome-erp-erp-sap-top">
            <img src={sap} alt="oracle-img" style={{ width: "100%" }} />
          </div>
          <div className="custome-erp-erp-oracle-bottom">
            <p style={{ marginBottom: "1em", fontSize: "1.2rem" }}>
              Our expertise covers full-scale support, migration, and
              implementation of SAP S/4HANA.
            </p>
            <a
              href="#"
              style={{ color: "blue", padding: "1em" }}
              onClick={() => {
                navigate("/platforms/SAP");
              }}
            >
              Learn More
            </a>
          </div>
        </div>
        {/* third */}
        <div className="custome-erp-erp-man">
          <div className="custome-erp-erp-man-top">
            <img src={man} alt="oracle-img" style={{ width: "100%" }} />
          </div>
          <div className="custome-erp-erp-oracle-bottom">
            <p style={{ marginBottom: "1em", fontSize: "1.2rem" }}>
              We offer seamlessly integrable applications that can be swiftly
              implemented and connected to your ERP system.
            </p>
            <a
              href="#"
              style={{ color: "blue", padding: "1em" }}
              onClick={() => {
                navigate("/services/Product-Development");
              }}
            >
              Learn More
            </a>
          </div>
        </div>
      </div>

      {/* erp-count */}
      <div className="custome-erp-erp-count" ref={ref}>
        <div className="custome-erp-erp-count-left">
          <h1>
            Go Live With Us <br /> And beyond.
          </h1>
          <hr
            style={{
              width: "2em",
              border: "none",
              borderTop: "0.1em solid #FFBD1E",
              marginTop: "1em",
            }}
          />

          <div className="custome-erp-erp-count-container">
            <div className="custome-erp-erp-client">
              <div className="custome-erp-erp-client-inner">
              <h3 style={{ fontSize: "1.4rem" }}>
                <span
                  style={{
                    fontSize: "30px",
                  }}
                >
                  {inView ? <CountUp start={0} end={100} duration={2.75} /> : 0}
                  +
                </span>
                <p>Happy Clients</p>
              </h3>
              </div>
              <div className='custome-erp-erp-client-inner'>
              <h3 style={{ fontSize: "1.4rem" }}>
                <span
                  style={{
                    fontSize: "30px",
                  }}
                >
                  {inView ? <CountUp start={0} end={250} duration={2.75} /> : 0}
                  +
                </span>
                <p>Employees Worldwide</p>
              </h3>
              </div>
            </div>
            <div className="custome-erp-erp-client">
              <div className="custome-erp-erp-client-inner">
              <h3 style={{ fontSize: "1.4rem" }}>
                <span
                  style={{
                    fontSize: "30px",
                  }}
                >
                  {inView ? <CountUp start={0} end={55} duration={2.75} /> : 0}+
                </span>
                <p>ERP Implementation</p>
              </h3>
              </div>
              <div className='custome-erp-erp-client-inner'>
              <h3 style={{ fontSize: "1.4rem" }}>
                <span
                  style={{
                    fontSize: "30px",
                  }}
                >
                  {inView ? <CountUp start={0} end={10} duration={2.75} /> : 0}+
                </span>
                <p>Countries Served</p>
              </h3>
              </div>
            </div>
            <div className="custome-erp-erp-client">
              <div className='custome-erp-erp-client-inner'>
              <h3 style={{ fontSize: "1.4rem" }}>
                <span
                  style={{
                    fontSize: "30px",
                  }}
                >
                  {inView ? <CountUp start={0} end={10} duration={2.75} /> : 0}+
                </span>
                <p>Years in Business</p>
              </h3>
              </div>
              <div className='custome-erp-more'>
              <div className="custome-erp-more-about">
              <p
                onClick={() => {
                  navigate("/contact");
                }}
              >
                More About Us
              </p>
            </div>
              </div>
            
            </div>
          </div>
          <hr
              style={{
                width: "2em",
                border: "none",
                borderTop: "0.1em solid red",
                marginTop: "20px",
              }}
            />
        </div>
        {/* erp-right */}
        <div className="custome-erp-erp-count-right">
          <img src={countmans} alt="countman" style={{ width: "100%" }} />
        </div>
      </div>
      {/* fifth  */}
      <div className="custome-erp-erp-fifth">
        <div className="custome-erp-erp-fifth-top">
          <h1 style={{ fontWeight: "normal", textAlign: "center" }}>
            Ready-to-use ERP apps
          </h1>
          <p style={{ textAlign: "center" }}>
            Use our collection of applications for your unique ERP requirements{" "}
          </p>
          <p style={{ textAlign: "center" }}>
            Easily integrate with your Oracle and SAP system.
          </p>
        </div>
        </div>
        <div className="custome-erp-erp-scroll-cont" style={{ '--time': '10s' }}>
          <div>
          <p
            style={{ fontFamily: "cursive", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/SaleSyncX");
            }}
          >
            SalesSyncX
          </p>
          <p
            style={{ fontFamily: "fantasy", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/Planify");
            }}
          >
            Planify
          </p>
          <p
            style={{ fontFamily: "monospace", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/ScanlogiX");
            }}
          >
            ScanlogiX
          </p>
          <p
            style={{ fontFamily: "sans-serif", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/BidConnectHub");
            }}
          >
            Bidconnect
          </p>
          <p
            style={{ fontFamily: "serif", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/SupplySyncX");
            }}
          >
            SupplySyncX
          </p>
          <p
            style={{ fontFamily: "initial", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products");
            }}
          >
            IntegraHub
          </p>
          <p
            style={{ fontFamily: "inherit", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/E-Proc");
            }}
          >
            E-Proc
          </p>
          </div>
          {/* <div>
          <p
            style={{ fontFamily: "cursive", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/SaleSyncX");
            }}
          >
            SalesSyncX
          </p>
          <p
            style={{ fontFamily: "fantasy", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/Planify");
            }}
          >
            Planify
          </p>
          <p
            style={{ fontFamily: "monospace", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/ScanlogiX");
            }}
          >
            ScanlogiX
          </p>
          <p
            style={{ fontFamily: "sans-serif", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/BidConnectHub");
            }}
          >
            Bidconnect
          </p>
          <p
            style={{ fontFamily: "serif", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/SupplySyncX");
            }}
          >
            SupplySyncX
          </p>
          <p
            style={{ fontFamily: "initial", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products");
            }}
          >
            IntegraHub
          </p>
          <p
            style={{ fontFamily: "inherit", fontSize: "1.5rem" }}
            // className='oracle-train'
            onClick={() => {
              navigate("/products/E-Proc");
            }}
          >
            E-Proc
          </p>
          </div> */}
        </div>
      

      {/* sixth */}
      <div className="custome-erp-erp-sixth">
        <div className="custome-erp-erp-sixth-top">
          <p>Turn your AI in ERP ideas to reality quickly</p>
        </div>
        <div className="custome-erp-erp-sixth-button">
          <div className='custome-erp-erp-sixth-bt'>
          <p
            onClick={() => {
              navigate("/contact");
            }}
          >
            START WITH A CALL
          </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ERP;
